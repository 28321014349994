import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"
import logo from "../images/rokit-media-logo.svg"
import FaPhoneSquareAlt from "../images/svg/faPhoneSquareAlt.svg"
import { TiSocialLinkedinCircular, TiSocialFacebookCircular, TiSocialTwitterCircular, TiSocialInstagram, TiLocationOutline } from "react-icons/ti"

const Header = ({location}) => {
        const [click, setClick] = useState(false)
        const navClick = () => setClick(!click)
    
        const [scroll, setScroll] = useState(false)
        const changeNav = () => {
            if(window.scrollY >= 40) {
                setScroll(true)
            } else {
                setScroll(false)
            }
        }
        useEffect(() => {
            window.addEventListener("scroll", changeNav, {passive: true})
            return () => {
                window.removeEventListener("scroll", changeNav, {passive: true})
            }
        }, [])

        let isHomeLocation = false
        isHomeLocation = location.pathname.split('/')[1] ===""

    return (
        <>
            <header className="headerNav flex justify-between items-center z-40">
                <div className="flex items-center text-2xl">
                    <div className="w-14 mx-3">
                        <a href="tel:+441279882147" aria-label="Contact Telephone Number" className="w-12 text-xl font-extrabold tracking-tight whitespace-normal text-black fill-current bg-transparent">
                            <span className="lg:hidden">
                                <img width="30" height="30" src={FaPhoneSquareAlt} alt="Contact Telephone" />    
                            </span>
                            <span className="text-xl text-black font-mono hidden lg:inline-block">#01279882147</span>
                        </a>
                    </div>
                </div>
                <div className="flex-1 relative" style={{maxWidth: "100px"}}>
                    {isHomeLocation?
                        <AnchorLink href="#top" aria-label="Scroll to Top of Page">
                            <img width="200px" height="96px" className={scroll? "max-h-10 lg:max-h-14 p-0 transition duration-500 ease-in-out" : "max-h-10 lg:max-h-14 p-0 transition duration-500 ease-in-out transform translate-y-10 scale-200"} src={logo} alt="Modern Website Design Agency" title="Rokit Media Website Design Agency" />
                        </AnchorLink>
                    :
                        <Link to="/" aria-label="Go to Home Page">
                            <img width="200px" height="96px" className={scroll? "max-h-10 lg:max-h-14 p-0 transition duration-500 ease-in-out" : "max-h-10 lg:max-h-14 p-0 transition duration-500 ease-in-out transform translate-y-10 scale-200"} src={logo} alt="Modern Website Design Agency" title="Rokit Media Website Design Agency" />
                        </Link>
                    }
                </div>
                <div className="headerBurger block p-2 lg:p-3 z-50">
                    <button className="focus:outline-none" aria-label="Open Menu" onClick={navClick}>
                        {!click? 
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-8 lg:w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-8 lg:w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        }
                    </button>
                </div>
            </header>
            <div id="sideNav" className={click? "sideNavActive" : "sideNavInActive"}>
                <ul className="mt-0">
                    <li className={scroll? " invisible" : "border-b-4 border-black leading-none py-10"}>
                    </li>
                    <li className="border-b-4 border-black leading-none">
                        <Link to="/about-rokit-media/" className="uppercase menu1" onClick={navClick}><p className={click? "menu1EaseIn heading-box-text" : "menu1EaseOut heading-box-text"}>About</p></Link>
                    </li>
                    <li className="border-b-4 border-black leading-none">
                        <Link to="/web-design-services/" className="uppercase menu2" onClick={navClick}><p className={click? "menu2EaseIn heading-box-text" : "menu2EaseOut heading-box-text"}>Services</p></Link>
                    </li>
                    <li className="border-b-4 border-black leading-none">
                        <Link to="/jamstack-eco-system/" className="uppercase menu4"onClick={navClick}><p className={click? "menu3EaseIn heading-box-text" : "menu3EaseOut heading-box-text"}>Ecosystem</p></Link>
                    </li>
                    <li className="border-b-4 border-black leading-none">
                        <Link to="/blog/" className="uppercase menu3" onClick={navClick}><p className={click? "menu4EaseIn heading-box-text" : "menu4EaseOut heading-box-text"}>Latest News</p></Link>
                    </li>
                    <li className="border-b-4 border-black leading-none">
                        <Link to="/contact-rokit-media/" className="uppercase menu5"onClick={navClick}><p className={click? "menu5EaseIn heading-box-text" : "menu5EaseOut heading-box-text"}>Contact</p></Link>
                    </li> 
                    <li className="border-b-4 border-black leading-none">
                        <a href="mailto:&#099;&#111;&#110;&#116;&#097;&#099;&#116;&#064;&#114;&#111;&#107;&#105;&#116;&#109;&#101;&#100;&#105;&#097;&#046;&#099;&#111;&#046;&#117;&#107;" className="uppercase menu6"onClick={navClick}><p className={click? "menu6EaseIn heading-box-text" : "menu6EaseOut heading-box-text"} aria-label="Rokit Media Email Address">Email Us</p></a>
                    </li>
                    <li className="border-b-4 border-black leading-none">
                        <a href="tel:+441279882147" className="uppercase menu7"onClick={navClick}><p className={click? "menu7EaseIn heading-box-text" : "menu7EaseOut heading-box-text"} aria-label="Rokit Media Telephone Number">01279 882 147</p></a>
                    </li>
                    <li className="border-b-4 border-black leading-none">
                        <Link to="/locations/" className="uppercase menu5"onClick={navClick}><p className={click? "menu8EaseIn heading-box-text" : "menu8EaseOut heading-box-text"}>Locations</p></Link>
                    </li>
                    <li className="border-b-4 border-black leading-none">
                        <Link to="/locations/bishops-stortford/" className="uppercase menu5 pl-4"onClick={navClick}><p className={click? "menu8EaseIn heading-box-text" : "menu8EaseOut heading-box-text"}>Bishops Stortford</p></Link>
                    </li>
                    <li className="border-b-4 border-black leading-none">
                        <Link to="/locations/cambridge/" className="uppercase menu5 menu5 pl-4"onClick={navClick}><p className={click? "menu8EaseIn heading-box-text" : "menu8EaseOut heading-box-text"}>Cambridge</p></Link>
                    </li>
                    <li className="border-b-4 border-black leading-none">
                        <Link to="/locations/london/" className="uppercase menu5 menu5 pl-4"onClick={navClick}><p className={click? "menu8EaseIn heading-box-text" : "menu8EaseOut heading-box-text"}>London</p></Link>
                    </li>
                    <li className="border-b-4 border-black leading-none py-2">
                        <a href="https://www.linkedin.com/company/rokitmedia/" target="_blank" rel="noreferrer" className="inline-block px-2" aria-label="Rokit Media on Linkedin">
                            <TiSocialLinkedinCircular size="40" className="pulse" />
                        </a>
                        <a href="https://www.facebook.com/Rokit-Media-101543631890296/" target="_blank" rel="noreferrer" className="inline-block px-2" aria-label="Rokit Media on Facebook">
                            <TiSocialFacebookCircular size="40" className="pulse" />
                        </a>
                        <a href="https://twitter.com/@rokit_media" target="_blank" rel="noreferrer" className="inline-block px-2" aria-label="Rokit Media on Twitter">
                            <TiSocialTwitterCircular size="40" className="pulse" />
                        </a>
                        <a href="https://www.instagram.com/rokit_media/" target="_blank" rel="noreferrer" className="inline-block px-2" aria-label="Rokit Media on Instagram">
                            <TiSocialInstagram size="40" className="pulse" />
                        </a>
                        <Link to="/locations/" aria-label="Locations we provide services to" className="inline-block px-2" onClick={navClick}>
                            <TiLocationOutline size="40" className="pulse" />
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}


export default Header