import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import backToTop from '../images/backtotop.svg'
import { navigate } from 'gatsby'
// import Cookies from './Cookies'
import SDBreadcrumb from "./StructuredData/SDBreadcrumb"
import SDLocalBusiness from "./StructuredData/SDLocalBusiness"

const Layout = ({children, location}) => {

    // Redirect Unsupported Browser (IE)
    const isSSR = typeof window === "undefined"
    let isIE
    !isSSR && ( isIE = /*@cc_on!@*/false || !!document.documentMode )
    isIE && (navigate(`/browser/`))

    const [scroll, setScroll] = useState(false)
    const visBackTop = () => {
        if(window.scrollY >= 80) {
            setScroll(true)
        } else {
            setScroll(false)
        }
    }
    useEffect(() => {
        window.addEventListener("scroll", visBackTop, {passive: true})
        return () => {
            window.removeEventListener("scroll", visBackTop, {passive: true})
        }
    }, [])
    let isHomeLocation = false
    isHomeLocation = location.pathname.split('/')[1] ===""
    return (
        <>
            <div id="top" className="invisible"></div>
            <Header location={location}/>
            <SDLocalBusiness />
            {!isHomeLocation &&(<SDBreadcrumb url={location.pathname} />)}
            {children}
            <Footer />
            <div id="backtotop" className={scroll? "visible fixed left-0 bottom-0 mt-0 ml-8 mb-8 mr-0 z-50 transition-all duration-75 scale-x-75" : "invisible"}>
                <AnchorLink href="#top">
                    <img src={backToTop} width="48px" height="48px" alt="Back to Top of Page"/>
                </AnchorLink>
            </div>
            {/* <Cookies location={location} /> */}
        </>
    )
}

export default Layout