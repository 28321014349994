import React from "react"
import { Helmet } from "react-helmet"

// JSON-LD

const SDLocalBusiness = () => {

    // feature: article
    // https://schema.org/ProfessionalService
    // https://developers.google.com/search/docs/data-types/local-business

    const writeData = () => {
        const scriptData = JSON.stringify(
            {
                "@context": "https://schema.org",
                "@type": "ProfessionalService",
                "description": "Website Design & Development in Herts & Essex",
                "additionalType": "https://en.wikipedia.org/wiki/Web_design",
                "@id": "https://www.rokitmedia.co.uk",
                "name": "Rokit Media",
                "url": "https://www.rokitmedia.co.uk",
                "telephone": "+441279882147",
                //"image": "https://rokitmedia.netlify.app/images/home-page-feature-image.png", //DEV
                "image": "https://www.rokitmedia.co.uk/images/home-page-feature-image.png", //PROD
                //"logo": "https://rokitmedia.netlify.app/images/rokit-media-logo.png", //DEV
                "logo": "https://www.rokitmedia.co.uk/images/rokit-media-logo.png", //PROD
                "address": {
                    "@type": "PostalAddress",
                    "name": "Rokit Media Address",
                    "streetAddress": "Unit 14 Golds Enterprise",
                    "addressLocality": "Bishops Stortford",
                    "addressRegion": "Hertfordshire",
                    "postalCode": "CM22 6JX",
                    "addressCountry": "UK",
                    "@id": "https://www.rokitmedia.co.uk/contact-rokit-media/"
                },
                "geo": {
                    "@type": "GeoCoordinates",
                    "longitude": 0.226700,
                    "latitude": 51.921054,
                    "name": "Rokit Media Geo Coordinates",
                },
                "openingHoursSpecification": [
                    {
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": [
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday"
                        ],
                        "opens": "09:00",
                        "closes": "05:00",
                        "name": "Opening Hours Mon-Fri",
                    }
                ],
            }
    )
        return(
            scriptData
        )}
    return(
        <>
        <Helmet>
            <script type="application/ld+json">
                {writeData()}
            </script>
        </Helmet>
        </>
    )
}

export default SDLocalBusiness