import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

// JSON-LD

const SDBreadcrumb = ({ url }) => {
    // feature: breadcrumb
    // https://schema.org/breadcrumb 
    // https://developers.google.com/search/docs/data-types/breadcrumb
    const crumbs = url.split('/')

    let itemListElement = []
    let precrumb = ""
    const host = "https://www.rokitmedia.co.uk"
    crumbs.map((crumb, index) => (
        (crumb !== "" &&
        itemListElement.push(
            {
                "@type": "ListItem",
                // "position": index+1,
                "position": index,
                "item": host+precrumb+"/"+crumb,
                "name": crumb = (crumb.replace(/\b([a-zA-Z]|\d+)/g, function($1) { return $1.toUpperCase() }).replace(/-/g, ' ')),
            }
        ),
        crumb = (crumb.replace(/\b([a-zA-Z]|\d+)/g, function($1) { return $1.toLowerCase() }).replace(/ /g, '-')),
        index!==0 && (precrumb = "/"+crumb))
    ))
    let breadCrumbs= [{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "name": "Rokit Media",
        "@id": host,
        itemListElement
    }]
    
    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(breadCrumbs)}
            </script>
        </Helmet>
    )
}

export default SDBreadcrumb

SDBreadcrumb.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
  }

  SDBreadcrumb.defaultProps = {
    url: "https://www.rokitmedia.co.uk",
    title: "home",
  }