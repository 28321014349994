import React from "react";
import footerRedBG from "../images/footer-red-background.svg";
import { Link } from "gatsby"
import { IoRocketOutline } from "react-icons/io5"

const Footer = () => {
  return (
    <>
      <div className="relative bg-red-700 mt-20" style={{ backgroundImage: "url(" + footerRedBG + ")", backgroundSize: "cover", overflow: "hidden", }}>
        <div className="container mx-auto text-center">
          <div className="w-full pt-8">
            <footer className="flex flex-col sm:flex-row px-8 pb-12 text-white-100">
              {/* Social Links */}
              <div className="flex-1 text-center z-20 ">
                <div className="inline-flex items-baseline heading-box-text2 text-2xl lg:text-3xl xl:text-4xl pt-3">
                    <span>Social</span>
                </div>
                <ul className="mt-4 leading-loose uppercase text-center font-bold text-white">
                  <li className="pb-1">
                    <span className="inline-flex items-baseline pulse">
                      <a
                        href="https://www.linkedin.com/company/rokitmedia/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        LinkedIn
                      </a>
                    </span>
                  </li>
                  <li className="py-1">
                    <span className="inline-flex items-baseline pulse">
                      <a
                        href="https://www.facebook.com/Rokit-Media-101543631890296/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Facebook
                      </a>
                    </span>
                  </li>
                  <li className="py-1">
                    <span className="inline-flex items-baseline pulse">
                      <a
                        href="https://twitter.com/@rokit_media"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Twitter
                      </a>
                    </span>
                  </li>
                  <li className="py-1">
                    <span className="inline-flex items-baseline pulse">
                      <a
                        href="https://www.instagram.com/rokit_media/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Instagram
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
              {/* Internal Links */}
              <div className="flex-1 text-center z-20">
                <div className="inline-flex items-baseline heading-box-text2 text-2xl lg:text-3xl xl:text-4xl pt-3">
                    <span>Links</span>
                </div>
                <ul className="mt-4 leading-loose uppercase text-center font-bold text-white">
                  <li className="pb-1">
                    <span className="inline-flex items-baseline pulse">
                      <Link to="/locations/">Locations</Link>
                    </span>
                  </li>
                  <li className="pb-1">
                    <span className="inline-flex items-baseline pulse">
                      <Link to="/locations/bishops-stortford/">Bishop's Stortford</Link>
                    </span>
                  </li>
                  <li className="pb-1">
                    <span className="inline-flex items-baseline pulse">
                      <Link to="/locations/cambridge/">Cambridge</Link>
                    </span>
                  </li>
                  <li className="pb-1">
                    <span className="inline-flex items-baseline pulse">
                      <Link to="/locations/london/">London</Link>
                    </span>
                  </li>
                  <li className="pb-1">
                    <span className="inline-flex items-baseline pulse">
                      <Link to="/terms-of-service/">Terms of Service</Link>
                    </span>
                  </li>
                  <li className="py-1">
                    <span className="inline-flex items-baseline pulse">
                      <Link to="/privacy-policy/">Privacy Policy</Link>
                    </span>
                  </li>
                  <li className="py-1">
                    <span className="inline-flex items-baseline pulse">
                      <Link to="/covid-19-policy/">Covid-19 Policy</Link>
                    </span>
                  </li>
                </ul>
              </div>
              {/* Contact Information */}
              <div className="flex-1 text-center z-20">
                <div className="inline-flex items-baseline heading-box-text2 text-2xl lg:text-3xl xl:text-4xl pt-3">
                    <span>Contact</span>
                </div>
                <ul className="mt-4 leading-loose uppercase text-center font-bold text-white">
                  <li className="py-1">
                    <span className="inline-flex items-baseline pulse">
                      <a href="mailto:&#099;&#111;&#110;&#116;&#097;&#099;&#116;&#064;&#114;&#111;&#107;&#105;&#116;&#109;&#101;&#100;&#105;&#097;&#046;&#099;&#111;&#046;&#117;&#107;">
                        Send us an email
                      </a>
                    </span>
                  </li>
                  <li className="py-1">
                    <span className="inline-flex items-baseline pulse">
                      <a href="tel:+441279882147">
                      (+44) 01279 882 147
                      </a>
                    </span>
                  </li>
                </ul>
                <p className="mt-4 uppercase text-center font-bold text-white">
                  Unit 14 Golds Enterprise
                  <br />
                  Jenkins Drive
                  <br />
                  Elsenham
                  <br />
                  Bishop's Stortford
                  <br />
                  CM22 6JX
                </p>
              </div>
            </footer>
          </div>
          <div className="w-full flex justify-center text-white pb-8">
              <span className="z-20 inline-flex items-center">&#169; Rokit Media Ltd {(new Date().getFullYear())} <IoRocketOutline className="m-1" /></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
